html, body, .ant-list {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  background: #fff;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Comfortaa', cursive;
  font-weight: 700; }

h1 {
  font-size: 20px; }

h2 {
  font-size: 18px; }

h3, h5, h6 {
  font-size: 16px; }

button, .thin {
  font-weight: 300; }

html, body, .root, .app-container {
  width: 100%;
  height: 100%;
  overflow: hidden; }

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

* {
  box-sizing: border-box; }

.hidden {
  display: none; }

.sans {
  font-family: 'Open Sans', sans-serif; }

@media only screen and (max-width: 1023px) {
  .mobile-hidden {
    display: none !important; } }

.mobile-only {
  display: none; }
  @media only screen and (max-width: 1023px) {
    .mobile-only {
      display: block; } }

@media only screen and (max-width: 1023px) {
  .one-column-hidden {
    display: none !important; } }

@media only screen and (min-width: 1024px) {
  .two-column-hidden {
    display: none !important; } }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.hide {
  display: none !important; }

.faded {
  opacity: 0.3; }

.pull-right {
  float: right; }

.spacer {
  margin: 0 0.5em; }

.top-margin {
  margin-top: 0.5em !important; }

.text-small {
  font-size: 0.875em !important;
  color: #aaa !important;
  line-height: 1.5; }

.round-border {
  border-radius: 9950vh !important; }

.padded-container {
  padding: 60px 40px; }
  @media only screen and (max-width: 550px) {
    .padded-container {
      padding: 60px 1.5rem; } }

.primary-gradient {
  color: #fff;
  background-color: #fc6f6f;
  /* For browsers that do not support gradients */
  background: linear-gradient(#fc6f6f, #fa7f92); }

.inversed-color {
  background-color: #fff !important;
  color: #fc6f6f !important;
  border-color: #fff !important; }

.padded-button {
  padding: 0.875em 2.25em !important;
  height: auto !important;
  font-weight: 300 !important;
  text-decoration: none !important;
  line-height: 1.2 !important; }
  .padded-button span {
    height: auto !important;
    line-height: 1.2 !important; }
  @media only screen and (max-width: 550px) {
    .padded-button {
      font-size: 0.8rem; } }

.pink {
  color: #fc6f6f; }

.pink-filled {
  background-color: rgba(252, 111, 111, 0.5); }

.top-banner-bar {
  display: none;
  color: #fff; }
  @media only screen and (max-width: 1023px) {
    .top-banner-bar {
      position: absolute;
      height: 50px;
      z-index: 320;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;
      background: #333; } }
  @media only screen and (max-width: 330px) {
    .top-banner-bar {
      font-size: 0.875rem; } }

@media only screen and (max-width: 1023px) {
  .with-banner {
    margin-top: 50px; } }

.split-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden; }
  .split-container .panel-left, .split-container .panel-right {
    width: 50%;
    height: 100%;
    float: left;
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
    position: relative; }
    @media only screen and (max-width: 1023px) {
      .split-container .panel-left, .split-container .panel-right {
        width: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute; } }
  .split-container .panel-left {
    background-color: #fff;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px 0 rgba(0, 0, 0, 0.2);
    z-index: 100; }
    .split-container .panel-left .back-button {
      display: none; }
    .split-container .panel-left .back-logo {
      display: none; }
    @media only screen and (max-width: 1023px) {
      .split-container .panel-left {
        left: 100%;
        box-shadow: none;
        z-index: 110;
        transition: all 0.4s cubic-bezier(0.6, 0.07, 0, 0.99); }
        .split-container .panel-left.active {
          left: 0; }
        .split-container .panel-left .back-button, .split-container .panel-left .back-logo {
          display: block;
          position: absolute;
          left: 0.75rem;
          top: 1.3rem;
          color: #fff;
          font-size: 1.65rem;
          z-index: 150; }
        .split-container .panel-left .back-button {
          text-shadow: rgba(0, 0, 0, 0.3) 0 0 5px; }
        .split-container .panel-left .back-logo {
          width: 31px;
          height: 45px;
          margin-top: 3px; } }
  .split-container .panel-right {
    z-index: 0; }
    @media only screen and (max-width: 1023px) {
      .split-container .panel-right {
        padding-top: 0;
        left: 0;
        z-index: 101; } }

.fake-link {
  cursor: pointer;
  color: #fc6f6f; }

[role="button"] {
  cursor: pointer; }

.hover-link {
  color: #707070;
  transition: color 0.3s ease; }
  .hover-link:hover {
    color: #fc6f6f; }

.ant-btn:active {
  text-decoration: none; }

.ant-carousel .slick-dots {
  bottom: 14px; }
  .ant-carousel .slick-dots li {
    border-radius: 50vh;
    overflow: hidden;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2); }
    .ant-carousel .slick-dots li button {
      opacity: 0.5;
      height: 4px;
      border-radius: 50vh;
      width: 24px;
      overflow: hidden; }
    .ant-carousel .slick-dots li.slick-active button {
      width: 36px; }

.center-loading {
  text-align: center;
  display: block;
  margin: 40px 0;
  color: #fc6f6f; }
  .center-loading.full-page {
    margin: 0;
    height: 60vh;
    line-height: 60vh; }
    .center-loading.full-page svg {
      display: inline-block; }

.diagonal-split-view {
  overflow-x: hidden;
  position: relative; }
  .diagonal-split-view .top-container {
    font-size: 1rem;
    padding: 30px 20px 150px 20px;
    text-align: center;
    color: #fff; }
    @media only screen and (max-width: 1023px) {
      .diagonal-split-view .top-container {
        padding: 23px 20px 150px 20px; } }
    @media only screen and (max-width: 550px) {
      .diagonal-split-view .top-container {
        font-size: 0.8rem; } }
    .diagonal-split-view .top-container .featured-date {
      display: inline-block;
      margin-bottom: 48px; }
    .diagonal-split-view .top-container h1 {
      font-size: 3.125em;
      margin-bottom: 0;
      color: #fff; }
    .diagonal-split-view .top-container h2 {
      margin-top: 0.75em;
      margin-bottom: 2rem;
      font-size: 1.25em;
      color: #fff;
      font-weight: 300; }
    .diagonal-split-view .top-container .checkitout-button {
      margin-top: 0.75rem; }
  .diagonal-split-view .diagonal-line {
    width: 110%;
    height: 150px;
    margin-left: -5%;
    margin-top: -2.5vw;
    position: absolute;
    background-color: #fff;
    transform: rotate(-5.5deg);
    z-index: 1; }
  .diagonal-split-view .bottom-container {
    text-align: center;
    z-index: 10;
    position: relative; }
    .diagonal-split-view .bottom-container .timeline-container {
      padding-top: 20px;
      text-align: left;
      font-size: 0.875rem;
      overflow: hidden;
      display: block; }
      .diagonal-split-view .bottom-container .timeline-container .left {
        float: left;
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 1.14;
        margin-top: -3px;
        color: #bbb;
        text-align: right;
        width: 50%;
        padding-right: 15px; }
        .diagonal-split-view .bottom-container .timeline-container .left li {
          padding-bottom: 25px; }
      .diagonal-split-view .bottom-container .timeline-container .ant-timeline {
        float: left;
        color: #666;
        width: 50%;
        margin-left: -5px; }
        .diagonal-split-view .bottom-container .timeline-container .ant-timeline .ant-timeline-item-last .ant-timeline-item-content {
          min-height: auto; }
    .diagonal-split-view .bottom-container .poki-button {
      display: inline-block;
      border: solid 1px #7e91ab;
      background-color: #000000;
      padding: 0.5em 1em;
      border-radius: 10px;
      margin-bottom: 40px; }
      .diagonal-split-view .bottom-container .poki-button img {
        margin-top: -4px;
        width: 106px; }

.left-margin {
  margin-left: 0.5rem; }

.left-padded {
  padding-left: 80px; }
  @media only screen and (max-width: 1400px) {
    .left-padded {
      padding-left: 40px; } }
  @media only screen and (max-width: 1023px) {
    .left-padded {
      padding-left: 20px; } }

.right-padded {
  padding-right: 80px; }
  @media only screen and (max-width: 1400px) {
    .right-padded {
      padding-right: 40px; } }
  @media only screen and (max-width: 1023px) {
    .right-padded {
      padding-right: 20px; } }

.heading {
  position: relative; }
  .heading h3 {
    font-size: 1.375rem;
    margin-bottom: 0;
    color: #fc6f6f; }
    .heading h3 .close-button {
      margin-left: 10px;
      color: #ccc;
      font-size: 16px;
      cursor: pointer; }
  .heading .heading-sub {
    color: #707070;
    font-size: 0.875rem;
    margin-bottom: 2.0rem; }
    @media only screen and (max-width: 1023px) {
      .heading .heading-sub {
        margin-bottom: 1.5rem; } }

.level-help ol {
  padding-left: 2em; }

.party-container {
  height: 42px;
  margin-bottom: -24px; }

.party {
  font-size: 1em;
  width: 1em;
  display: inline-block;
  margin: -0.5em 0 0 0.3125em; }
  .party.big {
    font-size: 1.875em;
    margin-left: 0; }

@font-face {
  font-family: "hunt-icon";
  src: url("styles/fonts/hunt-icon.eot");
  src: url("styles/fonts/hunt-icon.eot?#iefix") format("embedded-opentype"), url("styles/fonts/hunt-icon.woff") format("woff"), url("styles/fonts/hunt-icon.ttf") format("truetype"), url("styles/fonts/hunt-icon.svg#hunt-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "hunt-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "hunt-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-facebook:before {
  content: "\61"; }

.icon-twitter:before {
  content: "\62"; }

.icon-pinterest-p:before {
  content: "\63"; }

.icon-link:before {
  content: "\64"; }

header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 70px 80px 20px;
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 100; }
  @media only screen and (max-width: 1400px) {
    header {
      padding: 50px 40px 20px 40px; } }
  @media only screen and (max-width: 1023px) {
    header {
      position: sticky;
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      z-index: 110; } }
  header .nav-logo {
    width: 31px;
    height: 45px; }
  header .pull-right {
    text-align: right;
    margin-top: 2px; }
    header .pull-right .header-button {
      display: block;
      float: left; }
      header .pull-right .header-button.smaller {
        margin-top: 5px; }
    header .pull-right .ant-input-search {
      width: 200px; }
      header .pull-right .ant-input-search.active {
        display: block !important;
        position: absolute;
        z-index: 210;
        top: 12px;
        width: 100%;
        left: 0;
        height: 48px; }
        header .pull-right .ant-input-search.active input {
          border-radius: 0;
          border-left: 0;
          border-right: 0;
          padding-left: 20px; }
          header .pull-right .ant-input-search.active input:focus, header .pull-right .ant-input-search.active input:hover {
            border-color: #d9d9d9;
            box-shadow: none; }
      @media only screen and (max-width: 1400px) {
        header .pull-right .ant-input-search {
          width: 160px; } }
    header .pull-right .avatar-container {
      position: relative;
      width: 40px;
      height: 40px;
      margin-left: 8px; }
      header .pull-right .avatar-container .avatar-steemit {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: relative; }
    header .pull-right .right-margin {
      margin-right: 8px; }
      @media only screen and (max-width: 330px) {
        header .pull-right .right-margin {
          margin-right: 5px; } }
    header .pull-right .ant-btn-primary {
      text-decoration: none; }
      @media only screen and (max-width: 550px) {
        header .pull-right .ant-btn-primary {
          padding-left: 12px;
          padding-right: 12px; } }
      @media only screen and (max-width: 330px) {
        header .pull-right .ant-btn-primary {
          padding-left: 10px;
          padding-right: 10px; } }
      header .pull-right .ant-btn-primary:last-child {
        margin-right: 0; }

.menu-popover.ant-popover.ant-popover-placement-bottom .ant-popover-inner-content, .menu-popover.ant-popover.ant-popover-placement-bottom .ant-popover-arrow,
.menu-popover.ant-popover.ant-popover-placement-bottomRight .ant-popover-inner-content,
.menu-popover.ant-popover.ant-popover-placement-bottomRight .ant-popover-arrow {
  background: #001529;
  right: 15px !important; }

.menu-popover.ant-popover.ant-popover-placement-bottom .ant-popover-inner-content,
.menu-popover.ant-popover.ant-popover-placement-bottomRight .ant-popover-inner-content {
  padding: 0; }

.menu-popover.ant-popover.ant-popover-placement-bottom .menu-follow-button,
.menu-popover.ant-popover.ant-popover-placement-bottomRight .menu-follow-button {
  width: 100%;
  text-align: left;
  padding: 0;
  background-color: transparent;
  border: 0; }

.menu-popover.ant-popover.ant-popover-placement-bottom .share-buttons,
.menu-popover.ant-popover.ant-popover-placement-bottomRight .share-buttons {
  padding: 0; }

.menu-popover.ant-popover.ant-popover-placement-bottom .sub,
.menu-popover.ant-popover.ant-popover-placement-bottomRight .sub {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  line-height: 1;
  font-size: 0.75rem;
  cursor: default; }
  .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group,
  .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group {
    margin-bottom: 0.5rem; }
    .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group:last-child,
    .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group:last-child {
      margin-bottom: 0.3rem; }
    .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group .label,
    .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group .label {
      color: rgba(255, 255, 255, 0.5); }
    .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group .ant-progress,
    .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group .ant-progress {
      line-height: 1.2; }
      .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group .ant-progress .ant-progress-bg,
      .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group .ant-progress .ant-progress-bg {
        background-color: #fc6f6f; }
      .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group .ant-progress .ant-progress-inner,
      .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group .ant-progress .ant-progress-inner {
        background-color: rgba(255, 255, 255, 0.85); }
      .menu-popover.ant-popover.ant-popover-placement-bottom .sub .group .ant-progress .ant-progress-text,
      .menu-popover.ant-popover.ant-popover-placement-bottomRight .sub .group .ant-progress .ant-progress-text {
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.75rem;
        margin-left: 5px; }

.menu-popover.ant-popover.ant-popover-placement-bottomRight .ant-popover-inner-content, .menu-popover.ant-popover.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 20px; }

.coin-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  opacity: 0;
  z-index: 1;
  transition: all 1s; }
  .coin-container.play {
    top: -50px;
    opacity: 1;
    z-index: 1000; }
  .coin-container .text {
    font-size: 0.825rem;
    position: absolute;
    top: 4px;
    left: 30px; }

.coin {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px; }
  .coin.play {
    transform-style: preserve-3d;
    transform-origin: 50%;
    animation: spin 0.5s infinite;
    animation-timing-function: linear; }
  .coin .front, .coin .back {
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    background-color: #FEF236; }
  .coin .front {
    transform: translateZ(1.3px); }
  .coin .back {
    transform: translateZ(-1.3px) rotateY(180deg); }
  .coin .side {
    transform: translateX(11.7px);
    transform-style: preserve-3d;
    backface-visibility: hidden; }
    .coin .side .circle {
      height: 26px;
      width: 2.6px;
      position: absolute;
      transform-style: preserve-3d;
      backface-visibility: hidden; }
      .coin .side .circle:before, .coin .side .circle:after {
        content: '';
        display: block;
        height: 2.54845px;
        width: 2.6px;
        position: absolute;
        transform: rotateX(84.375deg);
        background: #E0D530; }
      .coin .side .circle:before {
        transform-origin: top center; }
      .coin .side .circle:after {
        bottom: 0;
        transform-origin: center bottom; }
      .coin .side .circle:nth-child(16) {
        transform: rotateY(90deg) rotateX(180deg); }
      .coin .side .circle:nth-child(15) {
        transform: rotateY(90deg) rotateX(168.75deg); }
      .coin .side .circle:nth-child(14) {
        transform: rotateY(90deg) rotateX(157.5deg); }
      .coin .side .circle:nth-child(13) {
        transform: rotateY(90deg) rotateX(146.25deg); }
      .coin .side .circle:nth-child(12) {
        transform: rotateY(90deg) rotateX(135deg); }
      .coin .side .circle:nth-child(11) {
        transform: rotateY(90deg) rotateX(123.75deg); }
      .coin .side .circle:nth-child(10) {
        transform: rotateY(90deg) rotateX(112.5deg); }
      .coin .side .circle:nth-child(9) {
        transform: rotateY(90deg) rotateX(101.25deg); }
      .coin .side .circle:nth-child(8) {
        transform: rotateY(90deg) rotateX(90deg); }
      .coin .side .circle:nth-child(7) {
        transform: rotateY(90deg) rotateX(78.75deg); }
      .coin .side .circle:nth-child(6) {
        transform: rotateY(90deg) rotateX(67.5deg); }
      .coin .side .circle:nth-child(5) {
        transform: rotateY(90deg) rotateX(56.25deg); }
      .coin .side .circle:nth-child(4) {
        transform: rotateY(90deg) rotateX(45deg); }
      .coin .side .circle:nth-child(3) {
        transform: rotateY(90deg) rotateX(33.75deg); }
      .coin .side .circle:nth-child(2) {
        transform: rotateY(90deg) rotateX(22.5deg); }
      .coin .side .circle:nth-child(1) {
        transform: rotateY(90deg) rotateX(11.25deg); }

@keyframes spin {
  from {
    transform: rotateX(0deg); }
  to {
    transform: rotateX(360deg); } }

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  z-index: 1010;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s; }

@-webkit-keyframes bang {
  to {
    box-shadow: -102px 38.33333px #ff00f2, 109px -188.66667px #aeff00, 57px -27.66667px #00fff2, -109px -178.66667px #ff0051, 46px 76.33333px #00ffae, -231px 20.33333px #ff5100, -225px -2.66667px #ff00bf, 185px -351.66667px #ff0009, 57px -70.66667px #0088ff, -93px -78.66667px #ff7b00, -139px -236.66667px #ff8800, 69px -71.66667px #0084ff, -191px -143.66667px #ff001e, 71px -123.66667px #ff0066, -86px -29.66667px #00ffea, 22px -209.66667px #00ffc4, -51px -353.66667px #ff9d00, 207px 4.33333px #00ffe6, 135px -49.66667px #ff0022, -40px -392.66667px #ff2600, 230px -262.66667px #1eff00, -117px -166.66667px #9d00ff, 182px 56.33333px #00ff2b, 97px -407.66667px #d500ff, 153px -221.66667px #88ff00, -88px -33.66667px #b300ff, 231px 44.33333px #bb00ff, -98px -401.66667px #ff0037, -85px -108.66667px #00e6ff, 195px -107.66667px #00bbff, 140px -386.66667px #003cff; } }

@-moz-keyframes bang {
  to {
    box-shadow: -102px 38.33333px #ff00f2, 109px -188.66667px #aeff00, 57px -27.66667px #00fff2, -109px -178.66667px #ff0051, 46px 76.33333px #00ffae, -231px 20.33333px #ff5100, -225px -2.66667px #ff00bf, 185px -351.66667px #ff0009, 57px -70.66667px #0088ff, -93px -78.66667px #ff7b00, -139px -236.66667px #ff8800, 69px -71.66667px #0084ff, -191px -143.66667px #ff001e, 71px -123.66667px #ff0066, -86px -29.66667px #00ffea, 22px -209.66667px #00ffc4, -51px -353.66667px #ff9d00, 207px 4.33333px #00ffe6, 135px -49.66667px #ff0022, -40px -392.66667px #ff2600, 230px -262.66667px #1eff00, -117px -166.66667px #9d00ff, 182px 56.33333px #00ff2b, 97px -407.66667px #d500ff, 153px -221.66667px #88ff00, -88px -33.66667px #b300ff, 231px 44.33333px #bb00ff, -98px -401.66667px #ff0037, -85px -108.66667px #00e6ff, 195px -107.66667px #00bbff, 140px -386.66667px #003cff; } }

@-o-keyframes bang {
  to {
    box-shadow: -102px 38.33333px #ff00f2, 109px -188.66667px #aeff00, 57px -27.66667px #00fff2, -109px -178.66667px #ff0051, 46px 76.33333px #00ffae, -231px 20.33333px #ff5100, -225px -2.66667px #ff00bf, 185px -351.66667px #ff0009, 57px -70.66667px #0088ff, -93px -78.66667px #ff7b00, -139px -236.66667px #ff8800, 69px -71.66667px #0084ff, -191px -143.66667px #ff001e, 71px -123.66667px #ff0066, -86px -29.66667px #00ffea, 22px -209.66667px #00ffc4, -51px -353.66667px #ff9d00, 207px 4.33333px #00ffe6, 135px -49.66667px #ff0022, -40px -392.66667px #ff2600, 230px -262.66667px #1eff00, -117px -166.66667px #9d00ff, 182px 56.33333px #00ff2b, 97px -407.66667px #d500ff, 153px -221.66667px #88ff00, -88px -33.66667px #b300ff, 231px 44.33333px #bb00ff, -98px -401.66667px #ff0037, -85px -108.66667px #00e6ff, 195px -107.66667px #00bbff, 140px -386.66667px #003cff; } }

@-ms-keyframes bang {
  to {
    box-shadow: -102px 38.33333px #ff00f2, 109px -188.66667px #aeff00, 57px -27.66667px #00fff2, -109px -178.66667px #ff0051, 46px 76.33333px #00ffae, -231px 20.33333px #ff5100, -225px -2.66667px #ff00bf, 185px -351.66667px #ff0009, 57px -70.66667px #0088ff, -93px -78.66667px #ff7b00, -139px -236.66667px #ff8800, 69px -71.66667px #0084ff, -191px -143.66667px #ff001e, 71px -123.66667px #ff0066, -86px -29.66667px #00ffea, 22px -209.66667px #00ffc4, -51px -353.66667px #ff9d00, 207px 4.33333px #00ffe6, 135px -49.66667px #ff0022, -40px -392.66667px #ff2600, 230px -262.66667px #1eff00, -117px -166.66667px #9d00ff, 182px 56.33333px #00ff2b, 97px -407.66667px #d500ff, 153px -221.66667px #88ff00, -88px -33.66667px #b300ff, 231px 44.33333px #bb00ff, -98px -401.66667px #ff0037, -85px -108.66667px #00e6ff, 195px -107.66667px #00bbff, 140px -386.66667px #003cff; } }

@keyframes bang {
  to {
    box-shadow: -102px 38.33333px #ff00f2, 109px -188.66667px #aeff00, 57px -27.66667px #00fff2, -109px -178.66667px #ff0051, 46px 76.33333px #00ffae, -231px 20.33333px #ff5100, -225px -2.66667px #ff00bf, 185px -351.66667px #ff0009, 57px -70.66667px #0088ff, -93px -78.66667px #ff7b00, -139px -236.66667px #ff8800, 69px -71.66667px #0084ff, -191px -143.66667px #ff001e, 71px -123.66667px #ff0066, -86px -29.66667px #00ffea, 22px -209.66667px #00ffc4, -51px -353.66667px #ff9d00, 207px 4.33333px #00ffe6, 135px -49.66667px #ff0022, -40px -392.66667px #ff2600, 230px -262.66667px #1eff00, -117px -166.66667px #9d00ff, 182px 56.33333px #00ff2b, 97px -407.66667px #d500ff, 153px -221.66667px #88ff00, -88px -33.66667px #b300ff, 231px 44.33333px #bb00ff, -98px -401.66667px #ff0037, -85px -108.66667px #00e6ff, 195px -107.66667px #00bbff, 140px -386.66667px #003cff; } }

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

.post-form {
  padding: 0 80px 0 60px; }
  @media only screen and (max-width: 1400px) {
    .post-form {
      padding: 0 40px 0 30px; } }
  @media only screen and (max-width: 550px) {
    .post-form {
      padding: 0 20px; } }
  .post-form .guideline {
    text-align: right; }
  .post-form .delete-button {
    cursor: pointer;
    margin-left: 5px; }
  .post-form .dropbox .ant-upload-list {
    display: block;
    float: none; }
  .post-form .dropbox .error {
    color: #f5222d; }
  .post-form .ant-upload-list-item {
    margin-top: 8px; }
  .post-form .ant-upload-list-item-info > span, .post-form .thumbnail-video-container {
    width: 100%;
    height: 100%; }
  .post-form .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img, .post-form .thumbnail-video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .post-form .description .ant-form-extra {
    text-align: right; }
  .post-form .submit-button {
    border: 0; }
  .post-form .inline-upload-container {
    position: absolute;
    width: 300px;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .post-form .inline-upload-container a {
      text-align: left; }
    .post-form .inline-upload-container .anticon-loading {
      margin-left: 10px;
      color: #fc6f6f; }
    .post-form .inline-upload-container input {
      display: none; }
  .post-form .singular-uploader.small img, .post-form .singular-uploader.small .uploader-placeholder {
    width: 128px;
    height: 128px; }
    @media only screen and (max-width: 550px) {
      .post-form .singular-uploader.small img, .post-form .singular-uploader.small .uploader-placeholder {
        width: 100px;
        height: 100px; } }
  .post-form .singular-uploader.large .ant-upload {
    width: 100%; }
    .post-form .singular-uploader.large .ant-upload img, .post-form .singular-uploader.large .ant-upload .uploader-placeholder {
      width: 100%;
      height: 250px;
      object-fit: cover; }
      @media only screen and (max-width: 550px) {
        .post-form .singular-uploader.large .ant-upload img, .post-form .singular-uploader.large .ant-upload .uploader-placeholder {
          height: 25vh; } }
  .post-form .singular-uploader .uploader-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.post-list {
  margin-bottom: 4.375rem; }
  @media only screen and (max-width: 1023px) {
    .post-list .heading {
      padding-right: 20px; } }
  .post-list .heading h3 {
    display: block;
    height: 2.1rem; }
    .post-list .heading h3.search-heading {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    @media only screen and (max-width: 330px) {
      .post-list .heading h3 {
        font-size: 1.2rem;
        padding-top: 0.125rem; } }
    .post-list .heading h3 .days-ago-text {
      display: block;
      float: left; }
    .post-list .heading h3 .shuffle-container {
      display: block;
      float: left;
      position: relative;
      margin-top: 3px;
      margin-left: 0.7rem; }
      .post-list .heading h3 .shuffle-container .shuffle-button {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        display: block;
        border: 1px solid #fc6f6f;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
        transition: top .15s;
        z-index: 10; }
        .post-list .heading h3 .shuffle-container .shuffle-button:hover, .post-list .heading h3 .shuffle-container .shuffle-button:active {
          color: #ff9c99;
          border-color: #ff9c99; }
  .post-list .heading .heading-sub {
    line-height: 1.8; }
  .post-list .heading .radio-option {
    text-align: right;
    position: absolute;
    right: 80px;
    top: 0; }
    @media only screen and (max-width: 1400px) {
      .post-list .heading .radio-option {
        right: 40px; } }
    @media only screen and (max-width: 1023px) {
      .post-list .heading .radio-option {
        right: 20px; } }
    @media only screen and (max-width: 550px) {
      .post-list .heading .radio-option {
        position: static;
        text-align: left;
        margin: 0.5rem 0; } }
  .post-list .heading .sort-option {
    text-align: right;
    position: absolute;
    right: 80px;
    top: 2px; }
    @media only screen and (max-width: 1400px) {
      .post-list .heading .sort-option {
        right: 40px; } }
    @media only screen and (max-width: 1023px) {
      .post-list .heading .sort-option {
        right: 20px; } }
    @media only screen and (max-width: 550px) {
      .post-list .heading .sort-option .text-small {
        display: none; } }
    .post-list .heading .sort-option .ant-select {
      width: 8.5rem;
      font-size: 0.75rem; }
    .post-list .heading .sort-option .help-hunt-score {
      margin-left: 5px;
      vertical-align: middle; }
  .post-list .heading .tag-option {
    text-align: right;
    position: absolute;
    right: 80px;
    top: 0; }
    @media only screen and (max-width: 1400px) {
      .post-list .heading .tag-option {
        right: 40px; } }
    @media only screen and (max-width: 1023px) {
      .post-list .heading .tag-option {
        right: 20px; } }
    @media only screen and (max-width: 550px) {
      .post-list .heading .tag-option {
        position: static;
        text-align: left;
        margin: 0.5rem 0; } }
    .post-list .heading .tag-option .ant-select {
      width: 8.5rem;
      font-size: 0.75rem; }
    .post-list .heading .tag-option .help-hunt-score {
      margin-left: 5px;
      vertical-align: middle; }
  .post-list .show-all {
    display: block;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto; }
  .post-list .post {
    overflow: hidden;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
    position: relative; }
    @media only screen and (max-width: 1023px) {
      .post-list .post {
        margin: 0 20px; } }
    .post-list .post.top-border {
      border-top: 1px solid #e8e8e8; }
    .post-list .post .rank {
      position: absolute;
      top: 10px;
      left: 0;
      width: 80px;
      height: 80px;
      line-height: 80px;
      font-size: 0.875rem;
      color: #fc6f6f;
      text-align: center; }
      @media only screen and (max-width: 1400px) {
        .post-list .post .rank {
          width: 40px; } }
      @media only screen and (max-width: 1023px) {
        .post-list .post .rank {
          display: none; } }
    .post-list .post .thumbnail {
      position: absolute;
      top: 10px;
      left: 80px;
      width: 80px;
      max-width: 80px;
      height: 80px;
      max-height: 80px;
      object-fit: cover;
      overflow: hidden; }
      .post-list .post .thumbnail video {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      @media only screen and (max-width: 1400px) {
        .post-list .post .thumbnail {
          left: 40px; } }
      @media only screen and (max-width: 1023px) {
        .post-list .post .thumbnail {
          left: 0;
          width: 80px;
          height: 80px; } }
    .post-list .post .summary {
      padding-left: 176px;
      padding-right: 130px;
      min-height: 80px; }
      @media only screen and (max-width: 1400px) {
        .post-list .post .summary {
          padding-left: 136px;
          padding-right: 90px; } }
      @media only screen and (max-width: 1023px) {
        .post-list .post .summary {
          padding-left: 94px;
          padding-right: 55px;
          min-height: 80px; } }
      .post-list .post .summary .title {
        font-size: 1.125rem; }
        @media only screen and (max-width: 1023px) {
          .post-list .post .summary .title {
            font-size: 1rem; } }
        .post-list .post .summary .title .verified {
          margin-left: 0.5em;
          font-size: 0.825em;
          color: #52c41a; }
      .post-list .post .summary .tagline, .post-list .post .summary .stats {
        font-size: 0.875rem; }
        @media only screen and (max-width: 1023px) {
          .post-list .post .summary .tagline, .post-list .post .summary .stats {
            font-size: 0.75rem; } }
      .post-list .post .summary .stats {
        color: #aaa;
        font-weight: 200;
        font-size: 0.8125rem;
        margin-top: 0.25rem; }
        @media only screen and (max-width: 1023px) {
          .post-list .post .summary .stats {
            margin-top: 0.125rem; } }
        .post-list .post .summary .stats .payout {
          color: #fc6f6f;
          font-weight: 200; }
      @media only screen and (max-width: 550px) {
        .post-list .post .summary {
          padding-right: 0; }
          .post-list .post .summary .title, .post-list .post .summary .tagline {
            padding-right: 42px; }
          .post-list .post .summary .stats {
            font-size: 0.75rem; } }
    .post-list .post .vote-section {
      position: absolute;
      right: 80px;
      top: 20px;
      text-align: center; }
      @media only screen and (max-width: 1400px) {
        .post-list .post .vote-section {
          right: 40px; } }
      @media only screen and (max-width: 1023px) {
        .post-list .post .vote-section {
          right: 2px;
          top: 26px; }
          .post-list .post .vote-section button {
            width: 26px;
            height: 26px;
            font-size: 12px; }
          .post-list .post .vote-section .payout-value {
            font-size: 0.75rem; } }

.post-container .post-signup {
  border: 1px dashed #ccc;
  padding: 1em;
  border-radius: 1em;
  background-color: #f3f3f3;
  text-align: center;
  margin-bottom: 70px; }
  .post-container .post-signup .signin-link {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    display: block; }

.post-container .comments {
  max-width: 80%;
  margin: 0 auto 80px auto; }
  @media only screen and (max-width: 1023px) {
    .post-container .comments {
      margin: 0 20px 60px 20px;
      max-width: none; } }
  .post-container .comments hr {
    margin-bottom: 1rem; }
    @media only screen and (max-width: 550px) {
      .post-container .comments hr {
        margin-bottom: 1.5rem; } }
  .post-container .comments h3 {
    color: #fc6f6f;
    font-size: 1em;
    margin-bottom: 0.5rem; }
    .post-container .comments h3 .fake-link {
      color: #fc6f6f; }
    .post-container .comments h3 .separator {
      color: #fc6f6f;
      margin: 0 0.5em;
      font-weight: 200; }
    .post-container .comments h3 .resteem-button {
      float: right;
      cursor: pointer;
      color: #707070; }
      .post-container .comments h3 .resteem-button.resteemed {
        color: #fc6f6f; }
  .post-container .comments .reply-form textarea {
    margin-top: 0.625rem;
    resize: none;
    border: 0;
    padding: 0.625rem 7rem 0.625rem 1rem;
    border-radius: 1.15625rem;
    background-color: #f3f3f3; }
    .post-container .comments .reply-form textarea:focus {
      -webkit-box-shadow: none;
      box-shadow: none; }
    @media only screen and (max-width: 550px) {
      .post-container .comments .reply-form textarea {
        padding-right: 6rem; } }
  .post-container .comments .reply-form .inline-upload-container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 5px;
    margin-top: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .post-container .comments .reply-form .inline-upload-container .fake-link {
      font-size: 0.8125rem; }
    .post-container .comments .reply-form .inline-upload-container .anticon-loading {
      margin-left: 10px;
      color: #fc6f6f; }
    .post-container .comments .reply-form .inline-upload-container input {
      display: none; }
  .post-container .comments .reply-form .actions {
    margin-top: -2.3125rem;
    margin-bottom: 1.5rem;
    margin-right: 0.125rem;
    text-align: right; }
    .post-container .comments .reply-form .actions button {
      margin: 0 0.125rem;
      border-radius: 50vh; }
      .post-container .comments .reply-form .actions button.close-button {
        color: #aaa;
        background-color: #ddd; }
        .post-container .comments .reply-form .actions button.close-button:hover, .post-container .comments .reply-form .actions button.close-button:focus, .post-container .comments .reply-form .actions button.close-button:active {
          background-color: #ccc;
          border: 0; }
  .post-container .comments .ant-list {
    overflow-x: scroll; }
  .post-container .comments .comment.flagged {
    opacity: 0.3; }
  .post-container .comments .comment .comment-body .comment .comment-body .comment .comment-body .comment .comment-body .comment {
    margin-left: -50px; }
  @media only screen and (max-width: 550px) {
    .post-container .comments .comment .comment-body .comment .comment-body .comment {
      margin-left: -50px; } }
  .post-container .comments .ant-list-item .ant-list-item {
    padding-bottom: 0; }
  .post-container .comments .ant-list-item-meta-title {
    margin-bottom: 0.375em;
    font-weight: 400; }
  .post-container .comments .separator {
    color: #ddd;
    margin: 0 0.75em; }
  .post-container .comments .comment-title .dislike-button {
    position: absolute;
    right: 0;
    color: #aaa;
    cursor: pointer; }
    .post-container .comments .comment-title .dislike-button.disliked {
      color: #fc6f6f; }
    .post-container .comments .comment-title .dislike-button.loading {
      color: #fc6f6f; }
  .post-container .comments .comment-title .author {
    display: inline-block; }
  .post-container .comments .comment-title .badge {
    margin-left: 5px;
    padding: 3px 6px;
    font-size: 0.625rem;
    color: #fff;
    border-radius: 2px;
    font-weight: 200; }
    .post-container .comments .comment-title .badge.team {
      background-color: #fc6f6f; }
    .post-container .comments .comment-title .badge.community-manager, .post-container .comments .comment-title .badge.social-manager {
      background-color: #50a7f9; }
    .post-container .comments .comment-title .badge.moderator {
      background-color: #87d461; }
    .post-container .comments .comment-title .badge.influencer {
      background-color: #45c5c1; }
  .post-container .comments .comment-title .separator, .post-container .comments .comment-title .date {
    font-size: 0.75em;
    color: #aaa; }
  .post-container .comments .comment-body {
    color: #707070;
    word-break: break-word; }
    .post-container .comments .comment-body p {
      margin-bottom: 0.5em; }
    .post-container .comments .comment-body img {
      max-width: 100%; }
    .post-container .comments .comment-body pre {
      overflow: auto;
      background-color: #efefef;
      max-width: 460px;
      padding: 0.5em 1em; }
      @media only screen and (max-width: 550px) {
        .post-container .comments .comment-body pre {
          max-width: 350px; } }
      @media only screen and (max-width: 550px) {
        .post-container .comments .comment-body pre {
          max-width: 280px; } }
      @media only screen and (max-width: 330px) {
        .post-container .comments .comment-body pre {
          max-width: 230px; } }
    .post-container .comments .comment-body li {
      list-style: disc; }
    .post-container .comments .comment-body blockquote {
      padding-left: 1rem;
      border-left: 1px solid #ddd;
      color: #aaa; }
    .post-container .comments .comment-body .actions {
      margin-bottom: 0.375em; }
      .post-container .comments .comment-body .actions .vote-button, .post-container .comments .comment-body .actions .vote-count {
        display: inline-block; }
      .post-container .comments .comment-body .actions .vote-button {
        margin-right: 0.75em;
        vertical-align: bottom; }
        .post-container .comments .comment-body .actions .vote-button .ant-btn-circle {
          width: 1.25rem;
          height: 1.25rem;
          font-size: 0.625rem; }
        .post-container .comments .comment-body .actions .vote-button .payout-value {
          margin-left: 0.75em; }

.ant-popover-content .voting-list {
  color: #aaa;
  min-width: 200px;
  position: relative; }
  .ant-popover-content .voting-list .influencer-dot {
    width: 5px;
    height: 5px;
    background-color: #45c5c1;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 9px;
    left: -10px; }
  .ant-popover-content .voting-list .value {
    float: right; }

.post-view .top-container .featured-date {
  background-color: #ff9daa;
  font-size: 0.75rem;
  padding: 0.125rem 0.9375rem; }

.post-view .top-container .edit-buttons {
  position: absolute;
  top: 30px;
  right: 30px; }
  .post-view .top-container .edit-buttons button {
    margin-left: 5px; }
  @media only screen and (max-width: 460px) {
    .post-view .top-container .edit-buttons {
      right: 14px;
      top: 18px; }
      .post-view .top-container .edit-buttons button {
        border: 0;
        font-size: 1.8rem;
        height: auto;
        line-height: 1; }
        .post-view .top-container .edit-buttons button span {
          display: none; } }

.post-view .bottom-container .carousel {
  display: block;
  margin-top: -100px;
  border-radius: 16px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 440px;
  height: 270px;
  box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px 0 rgba(0, 0, 0, 0.2); }
  @media only screen and (max-width: 460px) {
    .post-view .bottom-container .carousel {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: -105px; } }
  .post-view .bottom-container .carousel .slick-list {
    border-radius: 16px; }
  .post-view .bottom-container .carousel .slick-slide {
    position: relative;
    text-align: center;
    width: 100%;
    height: 270px;
    background: #d8d8d8;
    overflow: hidden; }
    .post-view .bottom-container .carousel .slick-slide .anticon {
      font-size: 3.125rem;
      line-height: 270px; }
      .post-view .bottom-container .carousel .slick-slide .anticon svg {
        display: inline-block; }
    .post-view .bottom-container .carousel .slick-slide .slide-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
      .post-view .bottom-container .carousel .slick-slide .slide-container img, .post-view .bottom-container .carousel .slick-slide .slide-container video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }

.post-view .bottom-container .description {
  font-size: 0.875rem;
  margin-top: 3rem;
  text-align: left;
  line-height: 1.8;
  border-left: 5px solid #fc6f6f;
  padding-left: 15px;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (max-width: 460px) {
    .post-view .bottom-container .description {
      margin-left: 20px;
      margin-right: 20px; } }
  .post-view .bottom-container .description p {
    margin-top: 0;
    margin-bottom: 0.825em; }
  .post-view .bottom-container .description img {
    max-width: 100%; }
  .post-view .bottom-container .description iframe {
    max-width: 100%; }
  .post-view .bottom-container .description blockquote {
    border-left: 2px solid #ccc;
    padding-left: 1em;
    color: #aaa; }
  .post-view .bottom-container .description h1 {
    font-size: 1.25rem; }
  .post-view .bottom-container .description h2 {
    font-size: 1.11rem; }
  .post-view .bottom-container .description h3 {
    font-size: 1.0rem; }
  .post-view .bottom-container .description h4, .post-view .bottom-container .description h5, .post-view .bottom-container .description h6 {
    font-size: 0.875rem; }
  @media only screen and (max-width: 550px) {
    .post-view .bottom-container .description {
      border-left: 0;
      padding: 0 5px; } }
  .post-view .bottom-container .description a {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom; }

.post-view .bottom-container .hunter {
  font-size: 0.875rem;
  color: #aaa;
  margin: 1.5rem 0; }

.post-view .bottom-container .vote-container {
  padding-bottom: 40px; }
  @media only screen and (max-width: 550px) {
    .post-view .bottom-container .vote-container {
      padding-bottom: 30px; } }
  .post-view .bottom-container .vote-container .share-container .share-button {
    margin-top: 2em;
    margin-bottom: 0.5em;
    border-radius: 50vh;
    padding: 2px 1.68rem;
    height: auto; }
  .post-view .bottom-container .vote-container .share-container .share-comment {
    color: #aaa;
    font-size: 0.875rem; }
    .post-view .bottom-container .vote-container .share-container .share-comment i {
      color: #aaa; }

.post-view .bottom-container .tags {
  padding-bottom: 40px; }
  .post-view .bottom-container .tags a {
    text-decoration: none; }
  @media only screen and (max-width: 550px) {
    .post-view .bottom-container .tags {
      padding-bottom: 30px; } }

.social-shares {
  display: flex; }
  .social-shares .vertical-line {
    border-left: 1px solid #757575;
    height: 24px;
    margin: auto 0; }
  .social-shares .share-button {
    height: 44px;
    width: 44px;
    margin: 0 5px;
    cursor: pointer; }
    .social-shares .share-button i.share-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-top: 3px;
      font-size: 25px;
      color: white; }

.social-popup .ant-popover-content .ant-popover-arrow {
  background-color: #001529;
  border-color: #001529; }

.social-popup .ant-popover-content .ant-popover-inner {
  background-color: #001529; }
  .social-popup .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    padding: 0px; }

.preview-modal {
  width: 60% !important;
  max-width: 60% !important; }
  @media only screen and (max-width: 550px) {
    .preview-modal {
      width: 100% !important;
      max-width: 100% !important; } }
  .preview-modal .ant-modal-content {
    background-color: transparent;
    box-shadow: none; }
    .preview-modal .ant-modal-content .ant-modal-body {
      padding-top: 60px; }
      @media only screen and (max-width: 550px) {
        .preview-modal .ant-modal-content .ant-modal-body {
          padding: 8px; } }
    .preview-modal .ant-modal-content img, .preview-modal .ant-modal-content video {
      display: block;
      max-width: 90%;
      height: 60vh;
      width: auto;
      margin: 0 auto;
      object-fit: contain; }
      @media only screen and (max-width: 550px) {
        .preview-modal .ant-modal-content img, .preview-modal .ant-modal-content video {
          max-width: 100%;
          height: 50vh; } }
    .preview-modal .ant-modal-content .prev, .preview-modal .ant-modal-content .next {
      position: absolute;
      top: 21px;
      width: 50%;
      height: 100%;
      line-height: 100%; }
      @media only screen and (max-width: 550px) {
        .preview-modal .ant-modal-content .prev, .preview-modal .ant-modal-content .next {
          top: 71px; } }
      .preview-modal .ant-modal-content .prev i, .preview-modal .ant-modal-content .next i {
        position: absolute;
        top: 50%;
        margin-top: -16px;
        font-size: 1.5rem;
        color: #ddd; }
        @media only screen and (max-width: 550px) {
          .preview-modal .ant-modal-content .prev i, .preview-modal .ant-modal-content .next i {
            font-size: 1.2rem; } }
    .preview-modal .ant-modal-content .prev {
      left: 1px; }
      .preview-modal .ant-modal-content .prev i {
        left: 16px; }
        @media only screen and (max-width: 550px) {
          .preview-modal .ant-modal-content .prev i {
            top: auto;
            bottom: 0; } }
    .preview-modal .ant-modal-content .next {
      right: 1px; }
      .preview-modal .ant-modal-content .next i {
        right: 16px; }
        @media only screen and (max-width: 550px) {
          .preview-modal .ant-modal-content .next i {
            top: auto;
            bottom: 0; } }
    @media only screen and (max-width: 550px) {
      .preview-modal .ant-modal-content .ant-modal-close {
        top: auto;
        bottom: -90px;
        left: 50%;
        right: auto;
        transform: translateX(-50%); } }
    .preview-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
      color: #ddd;
      font-size: 1.5rem; }

.moderation-modal .buttons {
  margin-top: 20px; }
  .moderation-modal .buttons button {
    margin-right: 10px; }

.moderation-modal .comment-button {
  height: 94px;
  margin-left: 5px; }

.post-list .vote-button {
  color: #fc6f6f;
  text-align: center; }
  .post-list .vote-button button {
    margin-bottom: 2px; }
  .post-list .vote-button .payout-value {
    font-size: 0.875rem; }

.post-view .vote-button button {
  font-size: 0.875rem;
  width: 120px;
  height: 120px; }
  .post-view .vote-button button i {
    vertical-align: top; }
  .post-view .vote-button button span {
    /* override */
    display: block;
    margin-left: 0; }

.vote-box {
  width: 240px;
  overflow: hidden;
  padding: 0 10px;
  touch-action: manipulation; }
  .vote-box .weight {
    float: left;
    margin-top: 5px; }
  .vote-box button {
    float: right; }

.profile .top-container {
  text-shadow: 1px 1px 1px #999; }
  .profile .top-container h1 {
    margin-top: 80px; }
  .profile .top-container .edit-buttons {
    position: absolute;
    top: 30px;
    right: 30px; }
    .profile .top-container .edit-buttons button {
      margin-left: 5px; }
  .profile .top-container .follow-button {
    background-color: rgba(255, 255, 255, 0.9) !important;
    color: #000 !important;
    box-shadow: 0px 0px 4px #ccc; }
    .profile .top-container .follow-button:disabled, .profile .top-container .follow-button[disabled] {
      color: #aaa !important; }

.profile .bottom-container {
  padding-bottom: 40px; }
  .profile .bottom-container .profile-picture {
    margin: -110px auto 20px auto;
    border-radius: 50%;
    overflow: hidden;
    width: 140px;
    height: 140px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px 0 rgba(0, 0, 0, 0.2); }
  .profile .bottom-container .profile-level {
    display: flex;
    justify-content: center;
    align-items: center; }
    .profile .bottom-container .profile-level h2 {
      color: #fc6f6f;
      margin-top: 1.5em;
      margin-bottom: 0; }
      .profile .bottom-container .profile-level h2 .fake-link {
        margin-left: 0.4em;
        vertical-align: -0.01em; }
    .profile .bottom-container .profile-level .level-bar {
      width: 300px;
      margin-top: 10px; }
      .profile .bottom-container .profile-level .level-bar .score {
        color: #fc6f6f;
        margin-bottom: 0.3em; }
      .profile .bottom-container .profile-level .level-bar .score-sub {
        font-size: 0.875rem; }
  .profile .bottom-container .other-info {
    color: #707070;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    line-height: 1.5; }
    .profile .bottom-container .other-info a, .profile .bottom-container .other-info a:visited, .profile .bottom-container .other-info a:active {
      color: #707070;
      text-decoration: none; }

.tags .top-container {
  padding: 10rem 0 11rem 0; }
  .tags .top-container h1 {
    margin: 0; }
  .tags .top-container h3 {
    color: white;
    font-weight: 200; }

.tags .bottom-container {
  padding: 0 20%; }
  .tags .bottom-container .profile-picture {
    margin: -110px auto 20px auto;
    border-radius: 50%;
    overflow: hidden;
    width: 140px;
    height: 140px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px 0 rgba(0, 0, 0, 0.2); }
  .tags .bottom-container .related-tags {
    margin: 3rem 0 1.5rem 0;
    color: #707070; }
  .tags .bottom-container .tag {
    margin: 0.3rem;
    display: inline-block; }
    .tags .bottom-container .tag a button {
      color: #ff9c99;
      border-color: #ff9c99;
      height: 36px;
      border-radius: 18px;
      padding: 0px 18px; }
    .tags .bottom-container .tag a button:hover {
      background: #FFEBEB; }

.full-page {
  min-height: 100vh; }

.main-logo {
  width: auto;
  height: 81px; }
  @media only screen and (max-width: 550px) {
    .main-logo {
      height: 60px; } }

.home-page, .not-found-page {
  position: relative; }
  .home-page.primary-gradient, .not-found-page.primary-gradient {
    background-color: #fc6f6f;
    /* For browsers that do not support gradients */
    background: linear-gradient(#fc6f6f, #fa7f92); }
  .home-page .center-content, .not-found-page .center-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding: 1rem;
    transform: translateY(-50%);
    text-align: center;
    color: #fff; }
    .home-page .center-content h1, .not-found-page .center-content h1 {
      font-size: 1.375rem;
      margin-top: 0.8rem;
      margin-bottom: 0;
      color: #fff; }
      @media only screen and (max-width: 550px) {
        .home-page .center-content h1, .not-found-page .center-content h1 {
          font-size: 1.1rem; } }
    .home-page .center-content h2, .not-found-page .center-content h2 {
      margin-top: 5rem;
      margin-bottom: 5rem;
      font-size: 2.375rem;
      font-weight: normal; }
      @media only screen and (max-width: 1400px) {
        .home-page .center-content h2, .not-found-page .center-content h2 {
          font-size: 2rem; } }
      @media only screen and (max-width: 550px) {
        .home-page .center-content h2, .not-found-page .center-content h2 {
          font-size: 1.5rem; } }
      @media only screen and (max-width: 330px) {
        .home-page .center-content h2, .not-found-page .center-content h2 {
          font-size: 1.45rem;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem; } }
    .home-page .center-content .notice, .not-found-page .center-content .notice {
      font-size: 0.75rem;
      padding: 0.8rem 0 1.2rem 0;
      border-bottom: 1px solid #fff;
      margin: 0 auto;
      text-align: left; }
      .home-page .center-content .notice a, .not-found-page .center-content .notice a {
        color: #fff;
        text-decoration: underline; }
    .home-page .center-content .ant-btn, .not-found-page .center-content .ant-btn {
      margin-top: 10rem;
      transition: all 0.3s ease; }
      .home-page .center-content .ant-btn:hover, .home-page .center-content .ant-btn:focus, .home-page .center-content .ant-btn:active, .home-page .center-content .ant-btn.active, .not-found-page .center-content .ant-btn:hover, .not-found-page .center-content .ant-btn:focus, .not-found-page .center-content .ant-btn:active, .not-found-page .center-content .ant-btn.active {
        background-color: rgba(255, 255, 255, 0.15) !important;
        border-color: #fff;
        color: #fff;
        text-decoration: none; }

.not-found-page .center-content {
  padding-top: 30vh; }
  .not-found-page .center-content h1 {
    font-size: 1.6875rem;
    margin-top: 4rem; }

.padded-page {
  padding: 105px 0;
  overflow: hidden;
  text-align: center; }
  @media only screen and (max-width: 550px) {
    .padded-page {
      padding: 80px 0; } }
  .padded-page h2 {
    font-size: 1.5rem; }
  .padded-page p, .padded-page a {
    font-weight: 300; }

.split-page {
  max-width: 310px;
  text-align: left;
  display: inline-block;
  font-size: 0.875rem; }
  @media only screen and (max-width: 550px) {
    .split-page {
      max-width: 84%; } }
  .split-page h2 {
    color: #fc6f6f; }
  .split-page p {
    color: #707070; }
  .split-page a {
    text-decoration: none !important; }
  @media only screen and (max-width: 550px) {
    .split-page .check-hall-of-fame {
      margin-bottom: 2rem;
      display: block; } }

.primary-gradient h2 {
  color: #fff; }

.grey-background {
  background-color: #f6f6f6; }
  .grey-background h2, .grey-background h3 {
    color: #fc6f6f; }

.columned-page {
  text-align: center;
  padding: 3rem;
  font-size: 0.8125rem;
  color: #9b9b9b; }
  .columned-page h2 {
    font-size: 1.5rem;
    margin-bottom: 2.5rem; }
  .columned-page img {
    width: 27px; }
  .columned-page h3 {
    font-size: 1.5rem;
    margin-top: 0.5rem; }
  .columned-page p {
    padding: 0 2rem; }

.card-page p {
  width: 330px;
  margin: 1rem auto 2rem auto; }

.page-3 {
  color: #fff; }
  .page-3 h2 {
    margin-bottom: 60px; }
  .page-3 .howto {
    max-width: 300px;
    margin: 20px auto; }
    .page-3 .howto .circle {
      display: inline-block;
      width: 80px;
      height: 80px;
      line-height: 80px;
      border-radius: 40px;
      font-size: 1.875rem;
      box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px 0 rgba(0, 0, 0, 0.2); }
      .page-3 .howto .circle .steem-token-logo {
        height: 34px; }
    .page-3 .howto h4 {
      color: #fff;
      font-size: 1.125rem;
      margin: 1.5rem 0 0.75rem 0; }
    .page-3 .howto p {
      font-size: 0.875rem; }
  .page-3 .splitter {
    font-size: 1.25rem;
    margin: 1.5rem 0;
    opacity: 0.9; }

.side-image {
  margin-left: 40px;
  display: inline-block;
  vertical-align: bottom; }
  @media only screen and (max-width: 550px) {
    .side-image {
      margin-left: 0; } }
  .side-image.cat {
    width: 201px;
    height: 282px; }
  .side-image.producthunt {
    width: 197px;
    height: 181px;
    margin-bottom: 30px; }

.page-last {
  height: 90vh;
  position: relative; }
  @media only screen and (max-width: 550px) {
    .page-last {
      height: 100vh; } }
  .page-last .left-container {
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 110;
    text-align: left; }
    @media only screen and (max-width: 1400px) {
      .page-last .left-container {
        top: 60px;
        left: 60px; } }
    @media only screen and (max-width: 550px) {
      .page-last .left-container {
        top: 40px;
        left: 40px; } }
    .page-last .left-container h2 {
      font-size: 2rem;
      line-height: 1.4;
      margin: 2.5rem 0;
      font-weight: normal; }
      @media only screen and (max-width: 550px) {
        .page-last .left-container h2 {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 330px) {
        .page-last .left-container h2 {
          font-size: 1.5rem; } }
    .page-last .left-container .post-button {
      display: inline-block;
      border: 1px solid #fff;
      color: #fff; }
  .page-last .bg-image {
    z-index: 101;
    height: 96vh;
    position: absolute;
    left: 76%;
    transform: translateX(-50%);
    bottom: 5%; }
    @media only screen and (max-width: 1400px) {
      .page-last .bg-image {
        height: 90vh; } }
    @media only screen and (max-width: 550px) {
      .page-last .bg-image {
        height: 82vh; } }
    @media only screen and (max-width: 330px) {
      .page-last .bg-image {
        height: 60vh; } }
  .page-last .footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 110;
    width: 100%;
    text-align: center; }
    .page-last .footer a {
      font-size: 0.75rem;
      color: #fff !important; }

.contents-page {
  padding: 140px 160px 50px 160px;
  color: #fff;
  text-align: left;
  background-color: #151515; }
  @media only screen and (max-width: 1400px) {
    .contents-page {
      padding: 70px 80px 35px 80px; } }
  @media only screen and (max-width: 550px) {
    .contents-page {
      padding: 80px 40px 45px 40px; } }
  .contents-page .page-block {
    margin-bottom: 70px; }
    @media only screen and (max-width: 1400px) {
      .contents-page .page-block {
        margin-bottom: 45px; } }
    .contents-page .page-block h1 {
      margin-left: auto;
      margin-right: auto;
      font-size: 2.8rem;
      line-height: 1.3em;
      color: #fc6f6f;
      text-align: left;
      margin-bottom: 1.5rem; }
      @media only screen and (max-width: 1400px) {
        .contents-page .page-block h1 {
          font-size: 2rem; } }
      @media only screen and (max-width: 550px) {
        .contents-page .page-block h1 {
          font-size: 1.7rem; } }
    .contents-page .page-block h2 {
      margin-left: auto;
      margin-right: auto;
      font-size: 1.375rem;
      line-height: 1.3em;
      color: #fc6f6f;
      text-align: left;
      margin-bottom: 1.5rem; }
      @media only screen and (max-width: 550px) {
        .contents-page .page-block h2 {
          font-size: 1.2rem; } }
      .contents-page .page-block h2.margin-top {
        margin-top: 5rem; }
      .contents-page .page-block h2.bottom-line {
        border-bottom: 0.5px solid #979797;
        padding-bottom: 5px;
        margin-bottom: 5px; }
      .contents-page .page-block h2 span {
        float: right;
        color: #fff;
        font-size: 0.75rem;
        font-weight: 300; }
    .contents-page .page-block a {
      color: #fc6f6f !important; }
    .contents-page .page-block .thin {
      margin-left: auto;
      margin-right: auto;
      font-size: 0.875rem; }
      .contents-page .page-block .thin .inner-block {
        margin-top: 1.5rem; }
    .contents-page .page-block .poll ul {
      list-style: none;
      margin-top: 0.5rem;
      padding-left: 1rem; }
      .contents-page .page-block .poll ul.selections {
        line-height: 2; }
      .contents-page .page-block .poll ul input[type=radio] {
        margin-right: 0.5em; }
    .contents-page .page-block .poll .result {
      margin-top: 1rem;
      color: #fc6f6f; }
    .contents-page .page-block .round-progress {
      display: inline-flex;
      flex-direction: column;
      margin: 0 5% 5% 0; }
      @media only screen and (max-width: 550px) {
        .contents-page .page-block .round-progress {
          max-width: 45%; } }
      .contents-page .page-block .round-progress .ant-progress .ant-progress-circle-trail {
        stroke: #4e4e4e !important; }
      .contents-page .page-block .round-progress .ant-progress .ant-progress-circle-path {
        stroke: #fc6f6f !important; }
      .contents-page .page-block .round-progress .ant-progress .ant-progress-text {
        color: #fff;
        font-size: 1.25rem; }
      .contents-page .page-block .round-progress .progress-text {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 0.75rem;
        text-align: center; }
    .contents-page .page-block .bar-progress {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.75rem; }
      .contents-page .page-block .bar-progress .ant-progress {
        margin-top: -3px; }
        .contents-page .page-block .bar-progress .ant-progress .ant-progress-inner {
          background-color: #151515; }
        .contents-page .page-block .bar-progress .ant-progress .ant-progress-bg {
          background-color: #fc6f6f; }
        .contents-page .page-block .bar-progress .ant-progress .ant-progress-text {
          color: #fff; }
      .contents-page .page-block .bar-progress .progress-text {
        font-size: 0.75rem; }
      .contents-page .page-block .bar-progress.disabled .ant-progress .ant-progress-inner {
        background-color: #151515; }
      .contents-page .page-block .bar-progress.disabled .ant-progress .ant-progress-bg {
        background-color: #4a4a4a; }
      .contents-page .page-block .bar-progress.disabled .ant-progress .ant-progress-text {
        color: #fff; }
    .contents-page .page-block .sign-up-samples {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
      position: relative; }
      .contents-page .page-block .sign-up-samples .sign-up-samples-left {
        width: 50%;
        text-align: right;
        padding-right: 5px; }
        .contents-page .page-block .sign-up-samples .sign-up-samples-left img {
          width: 50%; }
        .contents-page .page-block .sign-up-samples .sign-up-samples-left span {
          left: 0; }
      .contents-page .page-block .sign-up-samples .sign-up-samples-right {
        width: 50%;
        padding-left: 5px; }
        .contents-page .page-block .sign-up-samples .sign-up-samples-right img {
          width: 50%; }
        .contents-page .page-block .sign-up-samples .sign-up-samples-right span {
          right: 0; }
      .contents-page .page-block .sign-up-samples span.primary-round {
        background-color: rgba(252, 111, 111, 0.7);
        width: 150px;
        height: 150px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px; }
  .contents-page .icon-num {
    display: block;
    margin-top: 5rem;
    margin-bottom: 0.875rem;
    margin-left: auto;
    margin-right: auto;
    width: 39px;
    height: 39px; }
  .contents-page .action {
    display: block;
    margin: 2rem 0; }
    .contents-page .action.less-margin {
      margin: 1.2rem 0 3rem 0; }
    .contents-page .action .anticon {
      margin-left: 0.25rem;
      vertical-align: -0.15em; }
  .contents-page .image {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .contents-page .image.token {
      margin-top: 3rem;
      max-width: 590px; }
    .contents-page .image.sp-swap {
      max-width: 398px;
      margin-top: 3rem;
      margin-bottom: 5rem; }
    .contents-page .image.hunt-platform {
      max-width: 578px; }

.sign-up-form {
  margin-top: 60px; }
  @media only screen and (max-width: 550px) {
    .sign-up-form {
      margin-top: 40px; } }
  .sign-up-form h1 {
    margin-left: auto;
    margin-right: auto;
    font-size: 2rem;
    line-height: 1.3em;
    color: #fc6f6f;
    text-align: center;
    margin-bottom: 1.5rem; }
    @media only screen and (max-width: 1400px) {
      .sign-up-form h1 {
        font-size: 1.7rem; } }
    @media only screen and (max-width: 550px) {
      .sign-up-form h1 {
        font-size: 1.5rem; } }
  .sign-up-form .dont-have-account,
  .sign-up-form h3 {
    color: #707070; }
  .sign-up-form .login-button {
    margin-top: 1.625rem;
    height: 40px; }
  .sign-up-form .dont-have-account {
    cursor: pointer;
    margin-top: 1.25rem; }
  .sign-up-form .form-container {
    width: 368px;
    margin: 0 auto; }
    @media only screen and (max-width: 550px) {
      .sign-up-form .form-container {
        width: 90%; } }
    .sign-up-form .form-container img {
      width: 109px;
      height: 98px;
      object-fit: contain;
      margin: 40px auto 30px auto;
      display: flex; }
      .sign-up-form .form-container img.full-width {
        width: 100%;
        height: auto;
        margin: 0px; }
    .sign-up-form .form-container .cat-img {
      width: 196px;
      height: 283px;
      margin: 40px auto 60px auto;
      object-fit: contain; }
    .sign-up-form .form-container p {
      height: 66px;
      font-size: 1rem;
      text-align: center;
      color: #707070; }
    .sign-up-form .form-container .ant-form {
      margin-top: 40px; }
      .sign-up-form .form-container .ant-form .ant-row {
        margin-bottom: 10px; }
      @media only screen and (max-width: 550px) {
        .sign-up-form .form-container .ant-form .ant-input-suffix {
          display: none; } }
    .sign-up-form .form-container .actions-container {
      margin-bottom: 18px; }
      .sign-up-form .form-container .actions-container button {
        margin-bottom: 10px;
        height: 40px; }
    .sign-up-form .form-container .private-key-container {
      background-color: #f6f6f6;
      border: solid 1px #d9d9d9;
      border-style: dashed;
      border-radius: 4px;
      padding: 10px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fc6f6f;
      word-break: break-word;
      text-align: center;
      margin-bottom: 20px; }
  .sign-up-form p.form-tail {
    font-size: 0.8125rem;
    color: #aaa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .sign-up-form p.form-tail .anticon {
      vertical-align: -0.16em; }

.private-key-modal .ant-modal-body {
  padding: 60px 60px 0 60px;
  text-align: center; }
  @media only screen and (max-width: 550px) {
    .private-key-modal .ant-modal-body {
      padding: 40px 24px 0 24px; } }
  .private-key-modal .ant-modal-body h1 {
    color: #fc6f6f;
    margin-bottom: 41px; }
  .private-key-modal .ant-modal-body p {
    font-size: 1rem;
    text-align: center;
    color: #707070;
    margin-bottom: 34px; }

.private-key-modal .ant-modal-footer {
  border: none;
  padding: 0 60px 40px 60px;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 550px) {
    .private-key-modal .ant-modal-footer {
      padding: 0 24px 24px 24px;
      display: block; } }
  @media only screen and (max-width: 550px) {
    .private-key-modal .ant-modal-footer button {
      width: 100%;
      margin: 0 0 10px 0;
      height: 40px; } }
  @media only screen and (max-width: 330px) {
    .private-key-modal .ant-modal-footer button {
      font-size: 0.8rem; } }
