.postFeedEmbed {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.postFeedEmbed-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.PostFeedCard__thumbs iframe {
  border: 0;
}